.btn {
    line-height: 1.15;
}

.btn-blue {
    background-color: #1d1183;
    color: #fff;
}

.btn-darkblue {
    background-color: #173464;
    color: #fff;
    border-radius: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.toggle-button {
    margin-top: 6%;
    margin-right: 8%;
}

.cursor-dragging :active {
    user-select: none;
    cursor: -webkit-grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -o-grabbing !important;
    cursor: -ms-grabbing !important;
    cursor: grabbing !important;
}

.cursor-dragging {
    cursor: -webkit-grab !important;
    cursor: -moz-grab !important;
    cursor: -o-grab !important;
    cursor: -ms-grab !important;
    cursor: grab !important;
}

.upgrade-border {
    border-left-color: #1d1183;
    border-bottom-color: #1d1183;
    border-top-color: #4e4e4e;
    border-right-color: #4e4e4e;
}
