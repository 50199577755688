.antiClockwiseSpin {
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: antiClockwiseSpin;
    animation-timing-function: linear;
  }
  @keyframes antiClockwiseSpin {
    from  {
      transform: rotate(360deg);
   }
   to {
      transform: rotate(0deg);
    }	
  }

  .rotate-down{
  transform: rotate(0deg);
  transition: transform 0.25s linear;
}

.rotate-up{
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}