.max-window-height {
    max-height: calc(100vh - 7rem);
}

.max-window-height-two-rows {
    max-height: calc(100vh - 5rem);
}

.max-window-height-group {
    max-height: calc(100vh - 8rem);
}

.max-window-height-connections{
    max-height: calc(100vh - 10rem);
}

.max-window-height-audio-reviews{
    max-height: calc(100vh - 15rem);
}

.max-window-height-table {
    max-height: calc(100vh - 14rem);
}

.aspect-ratio-container {
    max-width: 450px;
}

.aspect-ratio-div {
    position: relative;
    padding-bottom: 56.2%;
}

.aspect-ratio-container-img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: auto;
}

.editor-class {
    min-height: 150px;
}

.max-audio-accordion-height {
    max-height: 300vh;
}

.rotate-down{
  transform: rotate(0deg);
  transition: transform 0.25s linear;
}

.rotate-up{
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.lineclamps-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}