.blue-primary {
    color: #1d1183;
}
.bg-blue-primary {
    background-color: #1d1183;
}
.gray-side-bar-heading {
    color: #868686;
}
.gray-side-bar-child {
    color: #4e4e4e;
}
.gray-border-line {
    border-color: #979797;
}

.stacked-bar-red-primary {
    background-color: #ee220c;
}

.stacked-bar-orange-primary {
    background-color: #ff9300;
}

.stacked-bar-green-primary {
    background-color: #1db100;
    color: #1db100;
}

.stacked-bar-blue-400 {
    background-color: #173564;
}

.stacked-bar-blue-300 {
    background-color: #0266c9;
}

.stacked-bar-blue-200 {
    background-color: #1d1183;
}

.stacked-bar-blue-100 {
    background-color: #56c1ff;
}

.magenta {
    background-color: #ff0982;
}

.toggle-input-back-ground {
    background-color: #1db100;
}

.toggle-back-ground {
    background: #1db100;
    opacity: 0.4;
}

.text-stacked-bar-orange-primary {
    color: #ff9300;
}

.text-stacked-bar-green-primary {
    color: #1db100;
}

.magenta-text {
    color: #ff0982;
}

.bg-pale-green {
    background-color: #81d553;
}

.bg-pale-orange {
    background-color: #e2792e;
}

.bg-red {
    background-color: #db3b26;
}
