/* @font-face {
    font-family: 'icomoon';
    /* src:  url('../../fonts/icomoon.eot') format('embedded-opentype'),
      url('../../fonts/icomoon.ttf') format('truetype'),
      url('../../fonts/icomoon.woff') format('woff'),
      url('../../fonts/icomoon.svg') format('svg'); 
    font-weight: normal;
    font-style: normal;
    font-display: block;
  } 
  */
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-GREEN-ARROW:before {
    content: "\e900";
    /* color: #00c975; */
    font-size : 32px;
  }
  .icon-HANDLE-ICON:before {
    content: "\e901";
    color: #7e7e7e;
  }